export const TagFilter = ({ tags, setTagsToFilterBy, tagsToFilterBy }) => {
  return (
    <div className="p-2">
      <div className="d-flex flex-wrap">
        {tags.map((tag) => {
          return (
            <div>
              <span
                className={
                  tagsToFilterBy.includes(tag)
                    ? "badge m-1 tagBgActive"
                    : "badge m-1 tagBgInactive"
                }
                onClick={() => {
                  setTagsToFilterBy(tag);
                }}
              >
                {tag}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
