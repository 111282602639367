import { InformationBanner } from "../Banners/InformationBanner"
import { NavigationAuth } from "../NavigationAuth"

export const PrivateWrapper = ({ children }) => {
    return (
        <>
            <NavigationAuth />
            <InformationBanner />
            {children}
        </>
    )
}