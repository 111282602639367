import AbstractAPIClient from "./abstractClient";

class SearchCalls extends AbstractAPIClient {
  constructor() {
    super();
    this.baseURL = "https://lyric-backend111.onrender.com/search/api";
  }

  async searchByCity(city) {
    try {
      const response = await this.getRequest(`${this.baseURL}/search/${city}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      window.location.href = "/login";
      return error
    }
  }
}

export default SearchCalls;
