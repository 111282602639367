import { createContext, useState } from "react";
import "./App.css";
import { Router } from "./router/Router";
import userCalls from "./api/userCalls";

export const Auth = createContext(null);

function App() {
  const calls = new userCalls();
  const [showNav, setShowNav] = useState(false);

  const [userProfile, setUserProfile] = useState({
    name: "John Doe",
    vocabulary: ["hello", "world"],
    sentences: ["Hello world"],
  });

  const getLatestInformation = async () => {
    const response = await calls.getUserInfo();
    if (response) {
      setUserProfile(response.doc || {
        name: "",
        vocabulary: [],
        sentences: [],
      });
    }
  };

  return (
    <Auth.Provider
      value={{
        showNav,
        setShowNav,
        getLatestInformation,
        userProfile,
      }}
    >
      <Router />
    </Auth.Provider>
  );
}

export default App;
