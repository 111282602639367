import userCalls from "../../api/userCalls";
import { VocabCard } from "../../components/cards/VocabCard";
import { useEffect, useState } from "react";
import { Title } from "../../components/text/Title";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TagFilter } from "../../components/TagFilter";
import { PrivateWrapper } from "../../components/wrappers/PrivateWrapper";

export const MyVocab = () => {
  const [vocab, setVocab] = useState([]);
  const [search, setSearch] = useState("");
  const [aggregatedTags, setAggregatedTags] = useState([]);
  const [tagsToFilterBy, setTagsToFilterBy] = useState([]);
  const [isShowingTags, setIsShowingTags] = useState(false);

  const userApi = new userCalls();

  const getUserVocab = async () => {
    const info = await userApi.getUserVocab();
    if (info.doc) {
      setVocab(info.doc);
    } else {
    }
  };

  useEffect(() => {
    const allTags = Array.from(new Set(vocab.flatMap((vocab) => vocab.tags)));
    setAggregatedTags(allTags);
  }, [vocab]);

  useEffect(() => {
    if (search !== "") {
      setSearch("");
    }
  }, [tagsToFilterBy]);

  useEffect(() => {
    if (tagsToFilterBy.length > 0) {
      setTagsToFilterBy([]);
    }
  }, [search]);

  useEffect(() => {
    getUserVocab();
  }, []);

  return (
    <PrivateWrapper>
      <div
        style={{
          width: "80%",
          margin: "auto",
        }}
      >
        <Title title="My vocabulary" />
        <div className="mt-5 d-flex flex-col lex-wrap">
          <div className="d-flex justify-content-between align-items-center f">
            <input
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              style={{
                backgroundColor: "#f2f2f2",
                border: "1px solid #eafdf2",
                width: "400px",
              }}
              className="form-control"
              placeholder="Search"
              value={search}
            />
            <button
              className="filterBtn d-flex align-items-center justify-content-around"
              style={{
                gap: "5px",
                border: isShowingTags ? "2px solid #eaeaea" : "",
              }}
              onClick={() => {
                if (isShowingTags) {
                  setTagsToFilterBy([]);
                }
                setSearch("");
                setIsShowingTags(!isShowingTags);
              }}
            >
              {tagsToFilterBy.length > 0 ? (
                <span className="tagCount">{tagsToFilterBy.length}</span>
              ) : (
                <FontAwesomeIcon icon={faFilter} />
              )}
              Tags
            </button>
          </div>
          {isShowingTags && (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <TagFilter
                  tags={aggregatedTags}
                  setTagsToFilterBy={(tag) => {
                    if (tagsToFilterBy.includes(tag)) {
                      setTagsToFilterBy(
                        tagsToFilterBy.filter((t) => t !== tag)
                      );
                    } else {
                      setTagsToFilterBy(
                        [...tagsToFilterBy, tag].filter((tag, index, self) => {
                          return self.indexOf(tag) === index;
                        })
                      );
                    }
                  }}
                  tagsToFilterBy={tagsToFilterBy}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {vocab &&
            search === "" &&
            tagsToFilterBy.length === 0 &&
            vocab?.map((word, index) => {
              return (
                <VocabCard
                  word={word?.word}
                  translation={word?.translation}
                  isOdd={index % 2 === 0}
                  dateSaved={word?.dateAdded}
                  language={word?.language}
                  rerenderInParent={getUserVocab}
                  tags={word?.tags}
                  id={word?.id}
                  definition={word?.definition}
                />
              );
            })}
          {vocab &&
            search !== "" &&
            tagsToFilterBy.length === 0 &&
            vocab
              .filter((word) => {
                return (
                  word.word.toLowerCase().includes(search.toLowerCase()) ||
                  word.translation.toLowerCase().includes(search.toLowerCase())
                );
              })
              .map((word, index) => {
                return (
                  <VocabCard
                    word={word?.word}
                    translation={word?.translation}
                    isOdd={index % 2 === 0}
                    dateSaved={word?.dateAdded}
                    language={word?.language}
                    rerenderInParent={getUserVocab}
                    tags={word?.tags}
                    id={word?.id}
                    definition={word?.definition}
                  />
                );
              })}
          {vocab.length === 0 && (
            <div className="mt-5 text-center">You have no sentences saved.</div>
          )}
          {vocab &&
            search !== "" &&
            vocab.filter((word) => {
              return (
                word.word.toLowerCase().includes(search.toLowerCase()) ||
                word.translation.toLowerCase().includes(search.toLowerCase())
              );
            }).length === 0 && (
              <div className="mt-5 text-center">No results found.</div>
            )}
          {tagsToFilterBy.length > 0 &&
            !search &&
            vocab
              .filter((word) => {
                return word.tags.some((tag) => tagsToFilterBy.includes(tag));
              })
              .map((word, index) => {
                return (
                  <VocabCard
                    word={word?.word}
                    translation={word?.translation}
                    isOdd={index % 2 === 0}
                    dateSaved={word?.dateAdded}
                    language={word?.language}
                    rerenderInParent={getUserVocab}
                    tags={word?.tags || []}
                    id={word?.id}
                    definition={word?.definition}
                  />
                );
              })}
        </div>
      </div>
    </PrivateWrapper>
  );
};
