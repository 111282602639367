import { useState, useEffect } from "react";
import userCalls from "../../api/userCalls";
import { UserInfoCard } from "../../components/cards/UserInfoCard";
import { Title } from "../../components/text/Title";
import { Link } from "react-router-dom";
import { PrivateWrapper } from "../../components/wrappers/PrivateWrapper";

export const MyThings = () => {
  const [user, setUser] = useState({});

  const userApi = new userCalls();

  const getUserInfo = async () => {
    const info = await userApi.getUserInfo();

    if (info.doc) {
      setUser(info.doc);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <PrivateWrapper>
      <div
        style={{
          width: "80%",
          margin: "auto",
        }}
      >
        <Title title={`Hi there 👋, ${user?.name}!`} />
        <p className="text-gray-500 mt-3">
          Here you can see your progress and your vocabulary.
        </p>
        <div>
          <Link to="/my-sentences">
            <UserInfoCard
              content="sentences"
              length={user?.sentences?.length}
            />
          </Link>
          <Link to="/my-words">
            <UserInfoCard content="words" length={user?.vocabulary?.length} />
          </Link>
        </div>
      </div>
    </PrivateWrapper>
  );
};
