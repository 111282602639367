import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";

import { RootLayout } from "../components/RootLayout";
import { Register } from "../pages/Auth/Register";
import { HomeAuth } from "../pages/Private/HomeAuth";
import { Login } from "../pages/Auth/Login";
import { Lyrics } from "../pages/Private/Lyrics";
import { MyVocab } from "../pages/Private/MyVocab";
import { SavedWord } from "../pages/Private/SavedWord";
import { MyThings } from "../pages/Private/MyThings";
import { MySentences } from "../pages/Private/MySentences";
import { Landing } from "../pages/Public/Landing";
import MySettings from "../pages/Private/MySettings";
import { ForgottenPassword } from "../pages/Public/ForgottenPassword";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<div>no</div>}>
      <Route path="/" element={<Landing />} />
      <Route path="/home-auth" element={<HomeAuth />} />
      <Route path="/song-searcher" element={<HomeAuth />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />
      <Route path="/lyrics/:id" element={<Lyrics />} />
      <Route path="/my-words/:word" element={<SavedWord />} />
      <Route path="/my-words" element={<MyVocab />} />
      <Route path="/my-stuff" element={<MyThings />} />
      <Route path="/my-sentences" element={<MySentences />} />
      <Route path="/my-settings" element={<MySettings />} />
      <Route path="/reset-password" element={<ForgottenPassword />} />
    </Route>
  )
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
