import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

export const Loading = ({
    isLoading,
    resource
}) => {
    if (!isLoading) return null
    return (
        <div className="loading">
            <div className="loading__content d-flex">
                <div className="loading__content__icon">
                    <FontAwesomeIcon icon={faSpinner} spin />
                </div>
                <div className="loading__content__text ml-2">
                    <p>Fetching {resource}</p>
                </div>
            </div>
        </div>
    )
}