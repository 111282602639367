import { useContext, useEffect, useState } from "react";
import "../../App.css";
import { Container, Row, Col } from "react-bootstrap";
import Search from "../../components/Search";
import Song from "../../components/cards/Song";
import data from "../../data";
import { Title } from "../../components/text/Title";
import { Link } from "react-router-dom";
import SearchCalls from "../../api/SearchCalls";
import OpenImage from "../../assets/open.png";
import { Auth } from "../../App";
import { useCountries } from "../../hooks/useCountries";
import { NavigationAuth } from "../../components/NavigationAuth";
import { PrivateWrapper } from "../../components/wrappers/PrivateWrapper";

export const HomeAuth = () => {
  const { countries } = useCountries();

  const searchCalls = new SearchCalls();
  const { setShowNav, getLatestInformation } = useContext(Auth);
  const [country, setCountry] = useState("United Kingdom");
  const [songs, setSongs] = useState([]);
  const [city, setCity] = useState(
    data.filter((item) => item.name === "United Kingdom")[0].cities[0].id
  );

  useEffect(() => {
    // get the users stats
    getLatestInformation();
  }, []);

  useEffect(() => {
    async function getSongs() {
      try {
        const data = await searchCalls.searchByCity(city);
        setSongs(data);
      } catch (error) {
      }
    }

    getSongs();
  }, [city]);

  // update the city when the country changes

  useEffect(() => {
    const dataFilter = data.filter((item) => item.name == country);
    const newCity = dataFilter[0].cities[0].id;
    setCity(newCity);
  }, [country]);

  return (
    <PrivateWrapper>
      <Container>
        <Search
          country={country}
          setCountry={setCountry}
          city={city}
          setCity={setCity}
        />
        <span>
          <Title
            title={`Popular in ${country}`}
            flag={countries.filter((item) => item.name == country)[0].emoji}
          />
        </span>
        <Row className="mt-5">
          {songs &&
            songs.map((song) => {
              return (
                <Col lg={3}>
                  <Link to={`/lyrics/${song.key}`}>
                    <Song
                      img={
                        song?.images?.coverart
                          ? song.images.coverart
                          : "https://i.pinimg.com/474x/37/c2/b7/37c2b739a12278c764b4e15991423cb4.jpg"
                      }
                      artist={song.subtitle}
                      title={song.title}
                      key={song.id}
                      songObject={song}
                      setCurrentSong={setSongs}
                    />
                  </Link>
                </Col>
              );
            })}
          {
            <Col lg={3}>
              <Song
                img={OpenImage}
                artist={"View all"}
                title={"~ 44 results"}
                isViewAll={true}
              />
            </Col>
          }
        </Row>
      </Container>
    </PrivateWrapper>
  );
};
