import SignUpForm from "../../components/auth/Register";
import authCalls from "../../api/authCalls";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { PublicWrapper } from "../../components/wrappers/PublicWrapper";
import { Container } from "react-bootstrap";

export const Register = () => {
  const calls = new authCalls();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [registerLoading, setRegisterLoading] = useState(false);

  const [hasSentVerificationEmail, setHasSentVerificationEmail] =
    useState(false);

  const register = async (
    registerEmail,
    registerPassword,
    nativeLanguage,
    targetLanguage,
    usersName
  ) => {
    setRegisterLoading(true);
    try {
      const user = await calls.register({
        email: registerEmail,
        password: registerPassword,
        nativeLanguage: nativeLanguage,
        targetLanguage: targetLanguage,
        usersName: usersName,
      });

      if (user === "OK") {
        setRegisterLoading(false);
        setHasSentVerificationEmail(true);
        navigate("/home-auth");
      }
    } catch (error) {
      setRegisterLoading(false);
      setError(error.message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  return (
    <PublicWrapper>
      {registerLoading && (
        <div className="alert alert-info">
          Registering your account, please be patient. You will be redirected
          shortly.
        </div>
      )}
      {hasSentVerificationEmail && (
        <Container className="d-flex flex-column align-items-center align-content-center min-vh-100">
          <div
            className="alert alert-success"
            role="alert"
            style={{
              width: "500px",
            }}
          >
            <h4 className="alert-heading">Email Sent!</h4>
            <p className="mt-3 pb-3">
              We have sent you an email with a link to verify your account. You
              must verify your account in order to use the features of
              LyricMster.
            </p>
            <hr />
            <p className="mt-3">
              If you do not see the email, please check your spam folder.
            </p>
          </div>
        </Container>
      )}
      {!hasSentVerificationEmail && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "70vh",
          }}
        >
          <SignUpForm
            register={(
              registerEmail,
              registerPassword,
              nativeLanguage,
              targetLanguage,
              usersName
            ) =>
              register(
                registerEmail,
                registerPassword,
                nativeLanguage,
                targetLanguage,
                usersName
              )
            }
            errorFromParent={error}
          />
        </div>
      )}
    </PublicWrapper>
  );
};
