import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useEffect } from "react";
import { faLanguage, faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VocabCalls from "../../api/VocabCalls";

export const UpdateSentence = ({
  show,
  handleClose,
  UpdateSentence,
  sentenceObject,
}) => {
  const [sentence, setSentence] = useState("");
  const [translation, setTranslation] = useState("");
  const [isTranslating, setIsTranslating] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagToAdd, setTagToAdd] = useState("");

  const vocabCalls = new VocabCalls();

  const handleTranslation = async () => {
    if (sentence.length === 0) {
      return;
    }
    setIsTranslating(true);
    const translation = await vocabCalls.translateSentence(
      sentenceObject.sentence
    );
    setIsTranslating(false);
    setIsTranslated(true);
    if (translation?.data?.translated) {
      UpdateSentence({
        sentence: sentenceObject.sentence,
        translation: translation?.data?.translated,
        _id: sentenceObject._id,
        tags: sentenceObject.tags,
      });

      setTranslation(translation?.data?.translated);
      setIsTranslated(true);
    }
  };

  useEffect(() => {
    setSentence(sentenceObject.sentence);
    setTranslation(sentenceObject.translation);
    setTags(sentenceObject.tags || []);
  }, [sentenceObject]);

  const handleUpdateSentence = () => {
    UpdateSentence({
      sentence,
      translation: translation || "No translation added.",
      _id: sentenceObject._id,
      tags: tags,
      dateAdded: sentenceObject.dateAdded,
      language: sentenceObject.language,
    });
    handleClose();
  };

  const hideModalAndReset = () => {
    handleClose();
  };

  const handleAddTag = (tag) => {
    setTags([...tags, tag]);
  };

  return (
    <>
      <Modal show={show} onHide={hideModalAndReset}>
        <Modal.Header closeButton>
          <Modal.Title>Update Sentence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="w-full h-40 p-2"
            placeholder="Add a sentence"
            onChange={(e) => {
              setSentence(e.target.value);
            }}
            value={sentence}
          ></textarea>
          <textarea
            className="w-full h-40 p-2"
            placeholder="Add a translation (optional)"
            onChange={(e) => {
              setTranslation(e.target.value);
            }}
            value={translation}
          ></textarea>
          <textarea
            className="w-full h-20 p-2 shadow-sm mt-2"
            placeholder="Add tags (optional)"
            onChange={(e) => {
              setTagToAdd(e.target.value);
            }}
          ></textarea>
          <button
            className="mt-2 bg-white shadow-sm p-2 br-2"
            onClick={() => {
              handleAddTag(tagToAdd);
            }}
          >
            Add Tag
          </button>
          {
            <div className="flex flex-wrap">
              {tags.map((tag) => {
                return (
                  <div
                    className="rounded-full px-4 py-1 mr-2 mt-2"
                    style={{
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    <span>{tag}</span>
                    <FontAwesomeIcon
                      icon={faRemove}
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        setTags(tags.filter((t) => t !== tag));
                      }}
                    />
                  </div>
                );
              })}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button
            className="addBtn"
            style={{
              borderRadius: "0.5rem",
            }}
            onClick={handleUpdateSentence}
          >
            Update
          </button>
          <button
            className="mr-5"
            onClick={() => {
              if (isTranslated) {
                return;
              } else {
                handleTranslation();
              }
            }}
          >
            <FontAwesomeIcon icon={faLanguage} />
            <span className="ml-2">
              {isTranslating && "Translating..."}
              {!isTranslating && !isTranslated && "Translate"}
              {isTranslated ? "Translated" : ""}
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
