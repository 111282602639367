import AbstractAPIClient from "./abstractClient";

class AuthAPICalls extends AbstractAPIClient {
  constructor() {
    super();
    // this.baseURL = "http://localhost:3001/flow/api";
    this.baseURL = "https://lyric-backend111.onrender.com/flow/api";
  }

  async login(data) {
    try {
      const response = await this.postRequest(`${this.baseURL}/login`, data, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async register(data) {
    try {
      const response = await this.postRequest(`${this.baseURL}/sign-up`, data, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getProfileValues() {
    try {
      const response = await this.getRequest(`${this.baseURL}/profile`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updatePassword(data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/update-password`,
        data,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/reset-password`,
        { email: data },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateEmail(data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/update-email`,
        {
          email: data,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateUser(data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/update-user`,
        {
          targetLang: data,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default AuthAPICalls;
