import React, { useContext } from "react";
import { Navigation } from "../components/Navigation";

import { Outlet } from "react-router-dom";
import { AnnouncementBanner } from "./Banners/AnnouncementBanner";
import { InformationBanner } from "./Banners/InformationBanner";
import { Container } from "react-bootstrap";
import { Auth } from "../App";

export const RootLayout = () => {

  const { showNav } = useContext(Auth);

  return (
    <>
      {/* {
        showNav ? <InformationBanner /> : null
      } */}
      {/* <AnnouncementBanner text="More languages coming soon!" /> */}
      {/* <Container> */}
        <Outlet />
      {/* </Container> */}
    </>
  );
};
