import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const Song = ({
  img,
  artist,
  title,
  isViewAll,
}) => {
  return (
    <div className="flex flex-col mt-5 position-relative">
      <img
        src={img}
        alt="song"
        style={{
          width: "300px",
          height: "300px",
        }}
        className="rounded-lg"
      />
      <h3 className="text-2xl mt-4 font-semibold">{title}</h3>
      <h4 className="mt-2 font-normal" style={{
        color: "#A0A0A0"
      }}>{artist}</h4>
      {isViewAll && (
        <div className="position-absolute" style={{ bottom: 0, right: 0 }}>
          <FontAwesomeIcon icon={faArrowCircleRight} className="text-2xl" />
        </div>
      )}
    </div>
  );
};

export default Song;
