import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faTag,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import VocabCalls from "../../api/VocabCalls";
import { useState, useContext } from "react";
import { Auth } from "../../App";

export const SentenceCard = ({
  sentence,
  language,
  translation,
  dateSaved,
  tags,
  rerenderInParent,
  id,
}) => {
  const { 
    getLatestInformation
  } = useContext(Auth);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTags, setIsEditingTags] = useState(false);

  const [updatedSentence, setUpdatedSentence] = useState(sentence);
  const [updatedTranslation, setUpdatedTranslation] = useState(translation);
  const [updatedTags, setUpdatedTags] = useState(tags || []);
  const [tagToAdd, setTagToAdd] = useState("");

  const handleDelete = async (id) => {
    const response = await calls.deleteSentence(id);
    rerenderInParent();
    getLatestInformation();
  };

  const handleEdit = async (id, newTag, updatedTags) => {
    const sentenceObject = {
      sentence: updatedSentence,
      language: language,
      translation: updatedTranslation,
      dateAdded: dateSaved,
      tags: newTag === "" ? updatedTags || tags : [...tags, newTag],
      _id: id,
    };

    const response = await calls.updateSentence(id, sentenceObject);

    setIsEditing(false);
    setIsEditingTags(false);
    rerenderInParent();
  };

  const handleAddTag = async (id, tagToAdd) => {
    await handleEdit(id, tagToAdd);
  };

  const handleRemoveTag = async (id, tagToRemove) => {
    const updatedTagsArray = updatedTags.filter((tag) => tag !== tagToRemove);
    setUpdatedTags(updatedTagsArray);
    await handleEdit(id, "", updatedTagsArray);
  };

  const functionality = [
    {
      name: "edit",
      icon: isEditing ? faClose : faEdit,
      function: () => {
        if (!isEditing) {
          setIsEditing(true);
        } else {
          setUpdatedSentence(sentence);
          setUpdatedTranslation(translation);
          setIsEditing(false);
          setIsEditingTags(false);
        }
      },
    },
    {
      name: "delete",
      icon: faTrash,
      function: (id) => {
        handleDelete(id);
      },
    },
    {
      name: "Tag",
      icon: faTag,
      function: () => {
        setIsEditingTags(!isEditingTags);
        setIsEditing(false);
      },
    },
  ];

  const calls = new VocabCalls();

  return (
    <>
      <div className="flex flex-col justify-content-between bg-white shadow-sm rounded-lg overflow-hidden mt-5">
        <div
          className="
          d-flex
          justify-content-between
          align-items-center
          px-6
          py-3
        "
        >
          <div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <p className="text-sm font-medium text-indigo-600">
                  <a href="#" className="hover:underline">
                    {language}
                  </a>
                </p>
                <a href="#" className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">
                    {sentence}
                  </p>
                  <p className="mt-3 text-base text-gray-500">{translation}</p>
                </a>
              </div>
              <div className="mt-6 flex items-center">
                <div>
                  <p
                    className="text-sm font-medium text-gray-900 d-flex"
                    style={{
                      gap: ".2rem",
                    }}
                  >
                    {tags?.map((tag) => {
                      return (
                        <div
                          className={
                            isEditingTags
                              ? "bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 hoverDelete"
                              : "bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
                          }
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            if (!isEditingTags) {
                              return;
                            } else {
                              handleRemoveTag(id, tag);
                            }
                          }}
                        >
                          {tag}
                        </div>
                      );
                    })}
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500 mt-3">
                    <div>{dateSaved}</div>
                    <span aria-hidden="true">&middot;</span>
                    <span>{language}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="p-6 d-flex cursorPointer"
            style={{
              gap: "1.5rem",
            }}
          >
            {functionality.map((item) => {
              return (
                <div
                  className="
            bg-gray-100
            hover:bg-gray-200
            rounded-full
            p-2
            transition
            duration-300
            ease-in-out
            d-flex
            justify-content-center
            align-items-center
            align-self-center
            align-content-center
            "
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                  onClick={() => {
                    item.function(id);
                  }}
                >
                  <FontAwesomeIcon icon={item.icon} />
                </div>
              );
            })}
          </div>
        </div>

        {isEditing && (
          <div
            className="p-6"
            style={{
              borderTop: "1px solid #e2e8f0",
            }}
          >
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mt-3"
              >
                <span
                  className="
            text-lg
            
            "
                >
                  Edit Sentence
                </span>
                <span className="ml-2">
                  from <span className="font-semibold">{sentence}</span> to{" "}
                  <span className="font-semibold">{updatedSentence}</span>
                </span>
              </label>
              <textarea
                id="message"
                rows="4"
                className="mt-3 block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Edit your sentence here..."
                onChange={(e) => {
                  setUpdatedSentence(e.target.value);
                }}
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium mt-3"
              >
                <span className="text-lg">Edit Translation</span>
                <span className="ml-2">
                  from <span className="font-semibold">{translation}</span> to{" "}
                  <span className="font-semibold">{updatedTranslation}</span>
                </span>
              </label>
              <textarea
                id="message"
                rows="4"
                className="mt-3 block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Edit your translation here..."
                onChange={(e) => {
                  setUpdatedTranslation(e.target.value);
                }}
              />
            </div>
            <button
              className="mt-3 text-white font-bold py-2 px-4 rounded"
              style={{
                backgroundColor: "#425249",
              }}
              onClick={() => {
                handleEdit(id);
              }}
            >
              Update Sentence
            </button>
          </div>
        )}
        {isEditingTags && (
          <div className="p-6">
            <p className="text-lg">
              Click on a tag to delete it and enter tag below to add it.
            </p>
            <textarea
              id="message"
              rows="4"
              className="mt-3 block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter new tag here..."
              onChange={(e) => {
                setTagToAdd(e.target.value);
              }}
            />
            <div
              className="d-flex"
              style={{
                gap: "1rem",
              }}
            >
              <button
                onClick={() => {
                  handleAddTag(id, tagToAdd);
                }}
                className="mt-3 text-white font-bold py-2 px-4 rounded"
                style={{
                  backgroundColor: "#425249",
                }}
              >
                Add Tag
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
