import React from "react";
import { Container, Col } from "react-bootstrap";
import Img from "../../assets/studying.svg";
import { Image } from "react-bootstrap";

function CTA() {
  return (
    <div
      style={{
        backgroundColor: "#30e981",
        minHeight: "50vh",
      }}
      className="mt-5 d-flex align-items-center"
    >
      <Container className="d-flex align-items-center">
        <Col xs={12} md={4} lg={6}>
          <div
            style={{
              color: "white",
            }}
            className="mt-3"
          >
            <h3 className="fw-bold fs-1">Join us!</h3>
            <p className="mt-3 fs-5">
              LyricMster is the simplest way to find new words from songs that
              are trending in the cities and towns that speak your language.
              Want to learn Portugese? Why not see what songs are trending in
              Lisbon? Want to learn Spanish? Why not see what songs are trending
              in Barcelona? We've got you covered.
            </p>
            <button
              className="btn  mt-3"
              style={{
                backgroundColor: "white",
                color: "#30e981",
                fontWeight: "bold",
              }}
            >
              Start learning
            </button>
          </div>
        </Col>
        <Col xs={12} md={4} lg={6} className="d-flex justify-content-end">
          <Image
            src={Img}
            style={{
              height: "300px",
              marginTop: "2rem",
            }}
          />
        </Col>
      </Container>
    </div>
  );
}

export default CTA;
