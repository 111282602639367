import React from "react";

export const SongInfo = ({ art, songName, lyricCount }) => {
  return (
    <div>
      <div
        className="d-flex rounded p-3 justify-content-between mt-5"
        style={{
          gap: "1rem",
        }}
      >
        <div
          className="d-flex"
          style={{
            gap: "1rem",
          }}
        >
          <img src={art} alt="cover art" className="songArt" />
          <div>
            <span className="font-semibold text-2xl">{songName}</span>
            <p className="font-normal" style={{
              color: "#848484"
            }}>Click on a word to save it to your vocabulary.</p>
          </div>
        </div>
        <div className="d-flex flex-col">
          <div className="d-flex flex-col">
            <span className="fw-normal">Vocab count</span>
            <span className="fw-normal">{lyricCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
