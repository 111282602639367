import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import axios from "axios";
import { Login as LoginContainer } from "../../components/auth/Login";
import { useEffect } from "react";
import { PublicWrapper } from "../../components/wrappers/PublicWrapper";
import AuthAPICalls from "../../api/authCalls";

export const Login = () => {
  const authAPICalls = new AuthAPICalls();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [error, setError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  const navigate = useNavigate();

  const emailInputRef = useRef(null);

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  const login = async () => {
    setLoginLoading(true);

    try {
      const loginBackend = await authAPICalls.login({
        loginEmail,
        loginPassword,
      });

      if (loginBackend === "OK") {
        setLoginLoading(false);
        navigate("/home-auth");
      }
    } catch (error) {
      if (error) {
        setLoginLoading(false);
        setError("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <PublicWrapper>
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{
          height: "70vh",
        }}
      >
        {error && (
          <div className="alert alert-danger mt-4" role="alert">
            {error}
          </div>
        )}
        {loginLoading && (
          <div className="alert alert-info mt-4">
            Logging you in. Please be patient.
          </div>
        )}
        <LoginContainer
          login={login}
          setLoginEmail={setLoginEmail}
          setLoginPassword={setLoginPassword}
          emailInputRef={emailInputRef}
        />
      </div>
    </PublicWrapper>
  );
};
