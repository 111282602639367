import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import AuthAPICalls from "../api/authCalls";
import { useNavigate } from "react-router-dom";
import { faGear } from "@fortawesome/free-solid-svg-icons";

export const NavigationAuth = () => {
  const calls = new AuthAPICalls();
  const redirect = useNavigate();

  const [showUserOptions, setShowUserOptions] = useState(false);

  const logUserOut = async () => {
    const logout = await calls.logout();
    redirect("/");
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" 
        style={{
          borderBottom: "1px solid #e9ecef",
        }}
      >
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto d-flex align-items-center">
              <Link to="/my-stuff" className="nav-link">
                My Things
              </Link>
              <Link to="/Song-searcher" className="nav-link">
                Song Searcher
              </Link>
            </Nav>
            <Nav>
              <Link
                to="/my-settings"
                className="circle d-flex justify-content-center align-items-center fw-bold"
                onClick={() => {
                  setShowUserOptions(!showUserOptions);
                }}
                style={{ cursor: "pointer", backgroundColor: "#F2F2F2" }}
              >
                <FontAwesomeIcon icon={faGear} /> 
              </Link>
              <button
                onClick={logUserOut}
                style={{
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                  marginLeft: "10px",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                className="fw-bold bg-danger"
              >
                Sign out
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
