export const VocabularyOptionsHeader = ({ art }) => {
  return (
    <div className="vocabularyOptionsHeader p-3 rounded position-relative mt-3 mb-3 d-flex align-items-center" style={{
        gap: "1rem",
    }}>
      <img src={art} alt="cover art" className="songArt" />
      <div>
        <h3
          className="text-2xl font-semibold"
          style={{
            color: "#434343",
          }}
        >
          Vocabulary
        </h3>
        <p
          className="text-normal font-semibold"
          style={{
            color: "#6E6E6E",
          }}
        >
          Save words to your vocabulary to review later
        </p>
      </div>
      <div className="vocabOptionsCircle" />
    </div>
  );
};
