import React, { useState, useEffect } from "react";
import { Form, Alert, Container } from "react-bootstrap";
import { PrivateWrapper } from "../../components/wrappers/PrivateWrapper";
import AuthAPICalls from "../../api/authCalls";
import { useNavigate } from "react-router-dom";

const MySettings = () => {
  const [email, setEmail] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("Spanish");
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [error, setError] = useState("");
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();

  const authAPICalls = new AuthAPICalls();

  const getProfileValues = async (e) => {
    const profileValues = await authAPICalls.getProfileValues();
    setProfile(profileValues);
  };

  const updateEmail = async (e) => {
    e.preventDefault();
    try {
      const data = await authAPICalls.updateEmail(email);
      if (data === "Email updated") {
        setError("");
        setEmail("");
        navigate("/login")
      }


      // Show success message or redirect on successful update
    } catch (error) {
      setError("Error updating email. Please check your credentials.");
    }
  };

  const updateValues = async (e) => {
    e.preventDefault();
    setUpdatingProfile(true);
    setError("");
    try {
      await authAPICalls.updateUser(targetLanguage);
      setUpdatingProfile(false);
      setEmail("");
      setTargetLanguage("");
      // Show success message or redirect on successful update
    } catch (error) {
      setError("Error updating user. Please check your credentials.");
      setUpdatingProfile(false);
    }
  };

  const updatePassword = async (e) => {
    e.preventDefault();
    setUpdatingPassword(true);
    setError("");
    try {
      await authAPICalls.updatePassword();
      setUpdatingPassword(false);
      // Show success message or redirect on successful update
    } catch (error) {
      setError("Error updating password. Please check your credentials.");
      setUpdatingPassword(false);
    }
  };

  useEffect(() => {
    getProfileValues();
  }, []);

  return (
    <PrivateWrapper>
      <Container>
        <h1
          className="mt-5 mb-5 fw-bold"
          style={{
            color: "#425249",
            fontSize: "2rem",
          }}
        >
          My Settings
        </h1>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form className="mt-5" onSubmit={updateValues}>
          <Form.Group controlId="formTargetLanguage" className="mt-3">
            <Form.Label>Target Language</Form.Label>
            <Form.Control
              as="select"
              value={targetLanguage}
              onChange={(e) => setTargetLanguage(e.target.value)}
            >
              <option>Spanish</option>
              <option>French</option>
              <option>German</option>
              <option>Italian</option>
              <option>Portuguese</option>
            </Form.Control>
            <Form.Text className="text-muted">
              This is the language you are learning. Your current target
              language is set to {profile?.targetLang}
            </Form.Text>
          </Form.Group>
          <button
            type="submit"
            className="mt-3"
            style={{
              backgroundColor: "#30e981",
              color: "#fff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Update Target Language
          </button>
        </Form>

        <Form className="mt-5" onSubmit={updateEmail}>
          <Form.Group controlId="formBasicEmail" className="mt-3">
            <Form.Label>Change Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Text className="text-muted">
              Your current email is set to {profile?.email}. You will be
              redirected to the login page after updating your email and will
              receive a confirmation email that may be in your spam folder.
            </Form.Text>
          </Form.Group>
          <button
            type="submit"
            className="mt-3"
            style={{
              backgroundColor: "#30e981",
              color: "#fff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Update Email
          </button>
        </Form>

        <button className="mt-5" onClick={updatePassword}>
          Get Password Reset Email
        </button>
      </Container>
    </PrivateWrapper>
  );
};

export default MySettings;
