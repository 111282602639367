import React from "react";
import data from "../data";
import { useCountries } from "../hooks/useCountries";
import { useEffect, useState } from "react";

const Search = ({ country, setCountry, city, setCity }) => {
  const { countries } = useCountries();

  const [countriesState, setCountriesState] = useState(countries);
  const [cityValue, setCityValue] = useState(city); // Set the initial value of cityValue based on the city prop

  useEffect(() => {
    const validCountries = data.map((item) => item.name);
    const filtered = countries.filter((item) => validCountries.includes(item.name));
    setCountriesState(filtered);
  }, [countries]);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    setCityValue(event.target.value);
  };

  return (
    <div className="flex p-3" style={{ margin: "0 auto", gap: "20px" }}>
      <div className="w-72">
        <select
          className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-indigo-500 bg-white"
          value={country}
          onChange={handleCountryChange}
        >
          {countriesState.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <select
        className="w-full px-4 py-2 rounded-lg border-2 border-gray-300 focus:outline-none focus:border-indigo-500 bg-white"
        value={cityValue}
        onChange={handleCityChange}
      >
        {data
          .filter((item) => item.name === country)
          .map((item) => (
            <optgroup key={item.name} label={item.name}>
              {item.cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
};

export default Search;
