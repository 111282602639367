import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { VocabularyOptions } from "../../components/cards/VocabularyOptions";
import { SongInfo } from "../../components/cards/SongInfo";
import VocabCalls from "../../api/VocabCalls";
import { Title } from "../../components/text/Title";
import { Auth } from "../../App";
import { PrivateWrapper } from "../../components/wrappers/PrivateWrapper";

export const Lyrics = () => {
  const { id } = useParams();
  const vocabCalls = new VocabCalls();

  const { setShowNav, getLatestInformation } = useContext(Auth);

  const [songDetails, setSongDetails] = useState({});
  const [lyrics, setLyrics] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [savedWords, setSavedWords] = useState([]);
  const [recentlySavedWords, setRecentlySavedWords] = useState([]);
  const [error, setError] = useState(null);
  const [isSavingWord, setIsSavingWord] = useState(false);
  const [isDeletingWord, setIsDeletingWord] = useState(false);

  useEffect(() => {
    getLatestInformation();
  }, [savedWords]);


  const removePunctuation = (str) => {
    return str.replace(/[^\w\s]|_/g, "");
  };

  async function getVocab() {
    const vocabData = await vocabCalls.getVocab();
    setSavedWords(vocabData);
  }

  useEffect(() => {
    const isAuth = localStorage.getItem("isAuthView");
    if (isAuth) {
      setShowNav(true);
    }
  }, []);

  useEffect(() => {
    const lyricsFilter = songDetails?.sections?.filter(
      (section) => section.type === "LYRICS"
    );

    if (lyricsFilter && lyricsFilter.length > 0) {
      setLyrics(lyricsFilter[0].text);
    } else {
      setLyrics([
        "Sorry, we don't have the lyrics for this song yet.",
        "It is possible that this song does not have lyrics.",
        "We will work on filtering it out in the future.",
        "However, you can save the words from this error message.",
        "If you want to.",
      ]);
    }
  }, [songDetails]);

  useEffect(() => {
    async function getDetails() {
      const songData = await vocabCalls.getSongDetails(id);
      setSongDetails(songData);
    }

    getDetails();
  }, [id]);

  async function saveVocabulary(word, savedWords) {
    setIsSavingWord(true);

    if (!savedWords) {
      return;
    }
    if (savedWords && savedWords.includes(word)) {
      setError("Word already saved");
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    } else {
      setSavedWords([...savedWords, word]);
      setRecentlySavedWords([...recentlySavedWords, word]);
      word = word.toLowerCase();
      await vocabCalls.addVocab({
        word: removePunctuation(word),
      });
    }
    await getVocab();
    setIsSavingWord(false);
  }

  async function deleteVocabulary(word) {
    setIsDeletingWord(true);
    setSavedWords(savedWords.filter((savedWord) => savedWord !== word));
    setRecentlySavedWords(
      recentlySavedWords.filter((savedWord) => savedWord !== word)
    );
    const deleteWord = await vocabCalls.deleteVocab(word);
    await getVocab();
    setIsDeletingWord(false);
  }

  useEffect(() => {
    getVocab();
  }, []);

  useEffect(() => {
    setSavedWords([]);
  }, []);

  const ItemsPerPage = 50;

  const TextSplitter = ({ text, savedWords }) => {
    const [page, setPage] = useState(1);

    const firstIndex = (page - 1) * ItemsPerPage;
    const lastIndex = firstIndex + ItemsPerPage;
    const currentText = text.slice(firstIndex, lastIndex);

    return (
      <div
        className="d-flex justify-content-center text-center p-4 rounded mt-4"
        style={{
          gap: "1.5rem",
          backgroundColor: "#fafafa",
          border: "2px solid #eaeaea",
        }}
      >
        <ul>
          {currentText.map((item, index) => (
            <li
              key={firstIndex + index}
              className="mt-3"
              style={{
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {item.split(" ").map((word, index) => (
                <span
                  key={index}
                  className="word ml-3 saveVocab"
                  onClick={() => {
                    saveVocabulary(word, savedWords);
                  }}
                >
                  {word}
                </span>
              ))}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <PrivateWrapper>
      <Container>
        <Row>
          <Col xs={12} lg={8} className="d-flex flex-column">
            <div>
              <Title title={"Popular"} />
              <SongInfo
                artistName={songDetails?.artist?.name}
                songName={songDetails?.title}
                lyricCount={lyrics?.length}
                art={songDetails?.images?.coverart}
              />
            </div>
            <div>
              {lyrics && <TextSplitter text={lyrics} savedWords={savedWords} />}
            </div>{" "}
          </Col>

          {isSidebarOpen && (
            <Col xs={12} lg={4} className="d-flex flex-column">
              <VocabularyOptions
                lyrics={[...lyrics]}
                recentlySavedWords={recentlySavedWords}
                savedWords={savedWords}
                deleteWord={(word) => {
                  deleteVocabulary(word);
                }}
                addWord={(word) => {
                  saveVocabulary(word);
                }}
                error={error}
                art={songDetails?.images?.coverart}
                isSavingWord={isSavingWord}
                isDeletingWord={isDeletingWord}
              />
            </Col>
          )}
        </Row>
      </Container>
    </PrivateWrapper>
  );
};
