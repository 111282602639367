import cleanseVocab from "../../utils/cleanseVocab";
import VocabCalls from "../../api/VocabCalls";
import { faEdit, faTag, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Auth } from "../../App";
import { useState, useEffect } from "react";


export const VocabCard = ({
  word,
  translation,
  dateSaved,
  language,
  rerenderInParent,
  tags,
  id,
  definition,
  setTags,
}) => {
  const calls = new VocabCalls();
  const { getLatestInformation } = useContext(Auth);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedWord, setUpdatedWord] = useState(word);
  const [updatedTranslation, setUpdatedTranslation] = useState(translation);
  const [updatedTags, setUpdatedTags] = useState([]);
  const [tagToAdd, setTagToAdd] = useState("");
  const [updatedDefinition, setUpdatedDefinition] = useState("");

  useEffect(() => {
    setUpdatedTags(tags || []);
    setUpdatedDefinition(definition || "");
  }, [tags, definition]);

  const handleDelete = async (word) => {
    const response = await calls.deleteVocab(word);
    rerenderInParent();
    getLatestInformation();
  };

  const handleEdit = async (id, updatedTags) => {
    const wordObject = {
      word: updatedWord,
      language: language,
      definition: updatedDefinition,
      translation: updatedTranslation,
      dateAdded: dateSaved,
      tags: updatedTags,
      id: id,
    };

    const response = await calls.updateVocab(id, wordObject);

    setIsEditing(false);
    setIsEditingTags(false);
    rerenderInParent();
  };

  const handleAddTag = (id, tagToAdd) => {
    if (tagToAdd.trim() !== "") {
      const updatedTagsArray = [...updatedTags, tagToAdd];
      setUpdatedTags(updatedTagsArray);
      setTagToAdd(""); // Clear the input field
      handleEdit(id, updatedTagsArray);
    }
  };

  const handleRemoveTag = async (id, tagToRemove) => {
    const updatedTagsArray = updatedTags.filter((tag) => tag !== tagToRemove);
    setUpdatedTags(updatedTagsArray);
    await handleEdit(id, updatedTagsArray);
  };

  const functionality = [
    {
      name: "edit",
      icon: faEdit,
      function: () => {
        setIsEditing(!isEditing);
      },
    },
    {
      name: "delete",
      icon: faTrash,
      function: (word) => {
        handleDelete(word);
      },
    },
    {
      name: "Tag",
      icon: faTag,
      function: () => {
        setIsEditingTags(!isEditingTags);
      },
    },
  ];
  return (
    <div className="flex flex-col bg-white shadow-sm rounded-lg overflow-hidden mt-5">
      <div className="justify-content-between flex">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-sm font-medium text-indigo-600">
              <a href="#" className="hover:underline">
                {language}
              </a>
            </p>
            <a href="#" className="block mt-2">
              <p className="text-xl font-semibold text-gray-900">
                {cleanseVocab(word)}
              </p>
              <p className="mt-3 text-base text-gray-500">{translation}</p>
            </a>
          </div>
          <div className="mt-6 flex items-center">
            <div>
              <p
                className="text-sm font-medium text-gray-900 d-flex"
                style={{
                  gap: ".2rem",
                }}
              >
                {tags.map((tag) => (
                  <div
                    className={
                      isEditingTags
                        ? "bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 hoverDelete"
                        : "bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (!isEditingTags) {
                        return;
                      } else {
                        handleRemoveTag(id, tag);
                      }
                    }}
                    key={tag} // Add a unique key prop to avoid rendering issues
                  >
                    {tag}
                  </div>
                ))}
              </p>
              <div className="flex space-x-1 text-sm text-gray-500 mt-3">
                <div>{dateSaved}</div>
                <span aria-hidden="true">&middot;</span>
                <span>{language}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-6 d-flex cursorPointer"
          style={{
            gap: "1.5rem",
          }}
        >
          {functionality.map((item) => {
            return (
              <div
                className="
            bg-gray-100
            hover:bg-gray-200
            rounded-full
            p-2
            transition
            duration-300
            ease-in-out
            d-flex
            justify-content-center
            align-items-center
            align-self-center
            align-content-center
            "
                style={{
                  width: "40px",
                  height: "40px",
                }}
                onClick={() => {
                  item.function(word);
                }}
              >
                <FontAwesomeIcon icon={item.icon} />
              </div>
            );
          })}
        </div>
      </div>
      {isEditingTags && (
        <div className="p-6">
          <p className="text-lg">
            Click on a tag to delete it and enter tag below to add it.
          </p>
          <textarea
            id="message"
            rows="4"
            className="mt-3 block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter new tag here..."
            onChange={(e) => {
              setTagToAdd(e.target.value);
            }}
          />
          <div
            className="d-flex"
            style={{
              gap: "1rem",
            }}
          >
            <button
              onClick={() => {
                handleAddTag(id, tagToAdd);
              }}
              className="mt-3 text-white font-bold py-2 px-4 rounded"
              style={{
                backgroundColor: "#425249",
              }}
            >
              Add Tag
            </button>
          </div>
        </div>
      )}
      {isEditing && (
        <div
          className="p-6"
          style={{
            borderTop: "1px solid #e2e8f0",
          }}
        >
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700 mt-3"
            >
              <span
                className="
        text-lg
        
        "
              >
                Edit Word
              </span>
              <span className="ml-2">
                from <span className="font-semibold">{word}</span> to{" "}
                <span className="font-semibold">{updatedWord}</span>
              </span>
            </label>
            <textarea
              id="message"
              rows="4"
              className="mt-3 block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Edit your word here..."
              onChange={(e) => {
                setUpdatedWord(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium mt-3">
              <span className="text-lg">Edit Translation</span>
              <span className="ml-2">
                from <span className="font-semibold">{translation}</span> to{" "}
                <span className="font-semibold">{updatedTranslation}</span>
              </span>
            </label>
            <textarea
              id="message"
              rows="4"
              className="mt-3 block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Edit your translation here..."
              onChange={(e) => {
                setUpdatedTranslation(e.target.value);
              }}
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium mt-3">
              <span className="text-lg">Edit Definition</span>
              <span className="ml-2">
                from <span className="font-semibold">{definition}</span> to{" "}
                <span className="font-semibold">{updatedDefinition}</span>
              </span>
            </label>
            <textarea
              id="message"
              rows="4"
              className="mt-3 block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Edit your translation here..."
              onChange={(e) => {
                setUpdatedDefinition(e.target.value);
              }}
            />
          </div>
          <button
            className="mt-3 text-white font-bold py-2 px-4 rounded"
            style={{
              backgroundColor: "#425249",
            }}
            onClick={() => {
              handleEdit(id);
            }}
          >
            Update Word
          </button>
        </div>
      )}
    </div>
  );
};
