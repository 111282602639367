import AbstractAPIClient from "./abstractClient";

class VocabCalls extends AbstractAPIClient {
  constructor() {
    super();
    this.baseURL = "https://lyric-backend111.onrender.com/vocab/api";
    // this.baseURL = "http://localhost:3001/vocab/api";
  }

  async addVocab(data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/add-vocab`,
        data,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateVocab(id, data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/update-vocab/${id}`,
        {
          wordObject: data,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addSentence(data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/add-sentence`,
        {
          sentenceObject: data,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteVocab(data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/delete-vocab`,
        {
          word: data,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getVocab() {
    try {
      const response = await this.getRequest(`${this.baseURL}/get-vocab`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSentences(word) {
    try {
      const response = await this.getRequest(
        `${this.baseURL}/get-sentences/${word}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSentence(id) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/delete-sentence/${id}`,
        {
          withCredentials: true,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getAssociatedWords(word) {
    try {
      const response = await this.getRequest(
        `${this.baseURL}/associated-vocab/${word}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getGeneratedSentences(word) {
    try {
      const response = await this.getRequest(
        `${this.baseURL}/generated-sentences/${word}`,
        {
          withCredentials: true,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateSentence(id, data) {
    try {
      const response = await this.postRequest(
        `${this.baseURL}/update-sentence/${id}`,
        {
          sentenceObject: data,
        },
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getDefinition(word) {
    try {
      const response = await this.getRequest(
        `${this.baseURL}/get-definition/${word}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async translateSentence(sentence) {
    const response = await this.postRequest(
      `${this.baseURL}/translate-sentence`,
      {
        sentence: sentence,
      },
      {
        withCredentials: true,
      }
    );
    return response;
  }

  async getSongDetails(id) {
    const response = await this.getRequest(
      `${this.baseURL}/get-song-details/${id}`,
      {
        withCredentials: true,
      }
    );
    return response.data;

    // return response;
  }
}

export default VocabCalls;
