import AbstractAPIClient from "./abstractClient";

class userCalls extends AbstractAPIClient {
  constructor() {
    super();
    this.baseURL = "https://lyric-backend111.onrender.com/user/api";
    // this.baseURL = "http://localhost:3001/user/api";
  }

  async getUserInfo() {
    try {
      const response = await this.getRequest(`${this.baseURL}/user-info`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUserVocab() {
    try {
      const response = await this.getRequest(`${this.baseURL}/user-vocab`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUserSentences() {
    try {
      const response = await this.getRequest(
        `${this.baseURL}/get-user-sentences`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default userCalls;
