import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import cleanseVocab from "../../utils/cleanseVocab";
import { Link } from "react-router-dom";

export const VocabItem = ({ word, deleteWord }) => {
  return (
    <span
      className="mr-3 pt-3 pb-3 flex flex-col justify-content-between"
      style={{
        borderBottom: "1px solid #F2F2F2",
      }}
    >
      <div
        className="
        flex justify-content-between cursor
        "
      >
        <Link to={`/my-words/${word}`} style={{ textDecoration: "none" }}>
          <span className="fw-semibold text-1xl">{cleanseVocab(word)}</span>
        </Link>   
        <div>
          <span
            className="ml-3 fw-normal removeBtn d-flex justify-content-evenly align-items-center"
            onClick={() => deleteWord(word)}
          >
            <span>Remove</span>
            <FontAwesomeIcon icon={faCircleXmark} />
          </span>
        </div>
      </div>
    </span>
  );
};
