import { Container, Form } from "react-bootstrap";
import { PublicWrapper } from "../../components/wrappers/PublicWrapper";
import authCalls from "../../api/authCalls";
import { Title } from "../../components/text/Title";

export const ForgottenPassword = () => {
  const calls = new authCalls();
  const handleResetPassword = async (email) => {
    const resetRequest = await calls.resetPassword(email);
  };

  return (
    <PublicWrapper>
      <Container>
        <Title title="Reset Password" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleResetPassword(e.target[0].value);
          }}
        >
          <Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              required
              className="mb-3"
              style={{
                width: "500px",
              }}
            />
            <Form.Text className="text-muted mt-3">
              If the email exists, we will send you a link to reset your
              password. Check your spam folder if you do not see the email.
            </Form.Text>
          </Form.Group>
          <button
            className="
            signUpBtn
          "
            style={{
              width: "200px",
            }}
            type="submit"
          >
            Reset Password
          </button>
        </Form>
      </Container>
    </PublicWrapper>
  );
};
