import { Container } from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
import { AddSentence } from "../../components/modals/AddSentence";
import { useParams } from "react-router-dom";
import VocabCalls from "../../api/VocabCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCirclePlus,
  faPenNib,
} from "@fortawesome/free-solid-svg-icons";
import { UpdateSentence } from "../../components/modals/UpdateSentence";
import { Loading } from "../../components/Loading";
import { Auth } from "../../App";
import { PrivateWrapper } from "../../components/wrappers/PrivateWrapper";

export const SavedWord = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);
  const [exampleSentences, setExampleSentences] = useState([]);
  const [associatedWords, setAssociatedWords] = useState([]);
  const [generatedExampleSentences, setGeneratedExampleSentences] = useState(
    []
  );
  const [generatedExampleSentencesError, setGeneratedExampleSentencesError] = useState(false)
  const [sentenceObjectEdit, setSentenceObjectEdit] = useState({});
  const [definition, setDefinition] = useState({});
  const [isLoadingDefinition, setIsLoadingDefinition] = useState(Boolean);
  const [isLoadingSentences, setIsLoadingSentences] = useState(Boolean);
  const [isLoadingAssociatedWords, setIsLoadingAssociatedWords] =
    useState(Boolean);
  const [isLoadingGeneratedSentences, setIsLoadingGeneratedSentences] =
    useState(Boolean);

  const vocabCalls = new VocabCalls();

  const { getLatestInformation } = useContext(Auth);

  const { word } = useParams();

  async function addSentence(sentenceObject) {
    await vocabCalls.addSentence(sentenceObject);
    fetchSentences(word);
    getLatestInformation();
  }

  async function deleteSentence(sentenceId) {
    await vocabCalls.deleteSentence(sentenceId);
    fetchSentences(word);
    getLatestInformation();
  }

  async function fetchSentences(word) {
    setIsLoadingSentences(true);
    const sentences = await vocabCalls.getSentences(word);
    setExampleSentences(sentences);
    setIsLoadingSentences(false);
  }

  async function fetchAssociatedWords(word) {
    setIsLoadingAssociatedWords(true);
    const associatedWords = await vocabCalls.getAssociatedWords(word);
    setAssociatedWords(
      associatedWords.translatedVocab || associatedWords.assoc_words
    );
    setIsLoadingAssociatedWords(false);
  }

  async function fetchGeneratedSentences(word) {
    try {
      setIsLoadingGeneratedSentences(true);
      const generatedSentences = await vocabCalls.getGeneratedSentences(word);
      setGeneratedExampleSentences(generatedSentences);
      setIsLoadingGeneratedSentences(false);
    } catch (error) {
      setGeneratedExampleSentencesError(true);
    }
  }

  async function updateSentence(sentenceObject) {
    sentenceObject = {
      ...sentenceObject,
      word: word,
    };
    await vocabCalls.updateSentence(sentenceObject._id, sentenceObject);
    fetchSentences(word);
    getLatestInformation();
  }

  function showUpdateModal(sentenceObject) {
    setSentenceObjectEdit(sentenceObject);
    handleShowUpdate();
  }

  useEffect(() => {
    fetchSentences(word);
    fetchAssociatedWords(word);
    fetchGeneratedSentences(word);
  }, [word]);

  useEffect(() => {
    async function getDefinition() {
      setIsLoadingDefinition(true);
      const definition = await vocabCalls.getDefinition(word);
      setDefinition(definition?.meaning);
      setIsLoadingDefinition(false);
    }
    getDefinition();
  }, [word]);

  function convertToDefinitionArray(definitionString) {
    const definitionArray = definitionString.split("\n");
    const trimmedArray = definitionArray.map((definition) => definition.trim());
    return trimmedArray;
  }

  return (
    <PrivateWrapper>
      <Container>
        <AddSentence
          show={show}
          handleClose={() => handleClose()}
          handleShow={() => handleShow()}
          addSentence={(sentenceObject) => addSentence(sentenceObject)}
          word={word}
        />
        <UpdateSentence
          show={showUpdate}
          handleClose={() => handleCloseUpdate()}
          handleShow={() => handleShowUpdate()}
          UpdateSentence={(sentenceObject) => updateSentence(sentenceObject)}
          sentenceObject={sentenceObjectEdit}
        />
        <div className="d-flex justify-content-between align-content-center">
          <div className="d-flex flex-column">
            <h1 className="text-5xl font-semibold mt-5">{word}</h1>
            <div className="italic mt-2">
              {isLoadingDefinition && (
                <Loading
                  status={"Loading"}
                  resource={"definitions"}
                  isLoading={true}
                />
              )}
              {definition &&
                definition?.meaning?.adjective.length > 0 &&
                "Adjective"}
              {definition &&
                definition?.meaning?.adverb.length > 0 &&
                (definition?.meaning?.adjective.length > 0 ? ", " : "") +
                  "Adverb"}
              {definition &&
                definition?.meaning?.noun.length > 0 &&
                (definition?.meaning?.adjective.length > 0 ||
                definition?.meaning?.adverb.length > 0
                  ? ", "
                  : "") + "Noun"}
            </div>
          </div>
        </div>
        <p className="mt-3">
          {definition && definition.result_msg === "Entry word not found" ? (
            "Sorry, we couldn't fetch a definition for that word. We are working to improve the site and will resolve this when we can."
          ) : (
            <div>
              {definition &&
                definition?.meaning?.adjective &&
                convertToDefinitionArray(definition.meaning.adjective).map(
                  (item) => {
                    return (
                      <div className="mt-4">
                        {" "}
                        <span>&#8226;</span> {item}
                      </div>
                    );
                  }
                )}
              {definition &&
                definition.adverb &&
                convertToDefinitionArray(definition.adverb)}
              {definition &&
                definition.noun &&
                // convertToDefinitionArray(definition.noun)}
                definition.noun.split("(nou)").map((item) => {
                  return (
                    <div className="mt-4">
                      {" "}
                      <span>&#8226;</span> {item}
                    </div>
                  );
                })}
            </div>
          )}
        </p>
        <div className="d-flex justify-content-between mt-5">
          <h2
            className="text-2xl"
            style={{
              color: "#A0AEC0",
            }}
          >
            SENTENCES ({exampleSentences.length})
          </h2>
          <button
            className="addBtn"
            onClick={() => {
              handleShow();
            }}
          >
            Add sentence
          </button>
        </div>
        {exampleSentences.length === 0 && (
          <div className="mt-5">
            <p className="mt-3 font-semibold">
              You have not added any sentences yet.
            </p>
          </div>
        )}
        {
          <div className="mt-5">
            {isLoadingSentences && (
              <Loading status={"Loading"} resource="sentences" />
            )}
          </div>
        }
        {exampleSentences &&
          exampleSentences?.map((item) => {
            return (
              <div
                style={{
                  borderBottom: "1px solid #E2E8F0",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mt-5 pb-3">
                    <p className="mt-3 font-semibold">{item.sentence}</p>
                    <p className="mt-1 italic" style={{ color: "#A0AEC0" }}>
                      Associated with: {item.word}
                    </p>
                    <p
                      style={{
                        color: "#A0AEC0",
                      }}
                      className="mt-1"
                    >
                      {item.translation}
                    </p>
                  </div>
                  <div className="d-flex">
                    <span
                      className="ml-3 fw-normal removeBtn d-flex justify-content-evenly align-items-center cursorPointer"
                      onClick={() => showUpdateModal(item)}
                    >
                      <span>Update</span>
                      <FontAwesomeIcon icon={faPenNib} />
                    </span>
                    <span
                      className="ml-3 fw-normal removeBtn d-flex justify-content-evenly align-items-center cursorPointer"
                      onClick={() => deleteSentence(item._id)}
                    >
                      <span>Remove</span>
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </span>
                  </div>
                </div>
                {
                  <div className="mb-3">
                    {item?.tags?.length > 0 && (
                      <div>
                        <div className="mt-1">
                          {item?.tags?.map((tag) => {
                            return (
                              <span className="mr-3">
                                <span className="tag bg-gray-100 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
                                  {tag}
                                </span>
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                }
              </div>
            );
          })}
        <div className="d-flex justify-content-between">
          <div>
            <h1
              className="text-2xl font-semibold mt-5"
              style={{
                color: "#A0AEC0",
              }}
            >
              Generated example sentences
            </h1>
            {
              <div className="mt-5">
                {isLoadingGeneratedSentences && (
                  <Loading status={"Loading"} resource="Generated Sentences" />
                )}
              </div>
            }
            {
              <div className="mt-5">
                {generatedExampleSentencesError && (
                  <p className="mt-3 font-semibold">
                    We ran into an error while generating sentences. Please try again later.
                  </p>
                )}
              </div>
            }
            <div
              className="mt-5"
              style={{
                color: "#A0AEC0",
              }}
            >
              {generatedExampleSentences &&
                generatedExampleSentences?.map((item) => {
                  return (
                    <div className="d-flex justify-content-between mt-5">
                      <div className="font-semibold">{item?.sentence}</div>
                      <span
                        className="ml-3 fw-normal removeBtn d-flex justify-content-evenly align-items-center cursorPointer"
                        onClick={() =>
                          addSentence({
                            sentence: item?.sentence,
                            translation: "No translation yet.",
                            word: word,
                          })
                        }
                      >
                        <span>
                          {exampleSentences.find(
                            (sentence) => sentence.sentence === item?.sentence
                          )
                            ? "Added"
                            : "Add"}
                        </span>
                        <FontAwesomeIcon icon={faCirclePlus} />
                      </span>
                    </div>
                  );
                })}
              {(generatedExampleSentences &&
                generatedExampleSentences.length === 0) ||
                (!generatedExampleSentences && (
                  <p className="mt-3 font-semibold">
                    We could not generate any sentences for this word.
                  </p>
                ))}
            </div>
          </div>
          <div>
            <h1
              className="text-2xl font-semibold mt-5"
              style={{
                color: "#A0AEC0",
              }}
            >
              RELATED WORDS
            </h1>
            {
              <div className="mt-5">
                {isLoadingAssociatedWords && (
                  <Loading status={"Loading"} resource="Associated Words" />
                )}
              </div>
            }
            <div
              className="mt-5"
              style={{
                color: "#A0AEC0",
              }}
            >
              {associatedWords &&
                associatedWords?.map((item) => {
                  return <div className="relatedWord">{item}</div>;
                })}
              {(associatedWords && associatedWords.length === 0) ||
                (!associatedWords && (
                  <p className="mt-3 font-semibold">
                    We could not find any related words for this word.
                  </p>
                ))}
            </div>
          </div>
        </div>
      </Container>
    </PrivateWrapper>
  );
};
