import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { faLanguage, faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VocabCalls from "../../api/VocabCalls";
import { useEffect } from "react";

export const AddSentence = ({ show, handleClose, addSentence, word }) => {
  const [sentence, setSentence] = useState("");
  const [translation, setTranslation] = useState("");
  const [isTranslating, setIsTranslating] = useState(false);
  const [isTranslated, setIsTranslated] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagToAdd, setTagToAdd] = useState("");

  useEffect(() => {
    setSentence("");
    setTranslation("");
    setIsTranslated(false);
    setTags([]);
    setTagToAdd("");
  }, [show])

  const vocabCalls = new VocabCalls();

  const handleAddSentence = () => {
    addSentence({
      sentence,
      translation: translation || "No translation added.",
      word,
      tags: tags?.length > 0 ? tags : ["These", "are", "example", "tags"],
    });
    handleClose();
  };

  const handleTranslation = async () => {
    if (sentence.length === 0) {
      return;
    }
    setIsTranslating(true);
    const translation = await vocabCalls.translateSentence(sentence);
    setIsTranslating(false);
    setIsTranslated(true);
    setTranslation(translation?.data?.translated);
  };

  const handleAddTag = (tag) => {
    setTags([...tags, tag]);
    setTagToAdd("");
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sentence</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="w-full h-20 p-2 shadow-sm"
            placeholder="Add a sentence"
            onChange={(e) => {
              setSentence(e.target.value);
            }}
          ></textarea>
          <textarea
            className="w-full h-20 p-2 shadow-sm mt-2"
            placeholder="Add a translation (optional)"
            onChange={(e) => {
              setTranslation(e.target.value);
            }}
            value={translation}
          ></textarea>
          <textarea
            className="w-full h-20 p-2 shadow-sm mt-2"
            placeholder="Add tags (optional)"
            onChange={(e) => {
              setTagToAdd(e.target.value);
            }}
          ></textarea>
          <button className="mt-2 bg-white shadow-sm p-2 br-2" onClick={() => {
            handleAddTag(tagToAdd)
          }}>Add Tag</button>
          {
            <div className="flex flex-wrap">
              {tags.map((tag) => {
                return (
                  <div className="rounded-full px-4 py-1 mr-2 mt-2" style={{
                    backgroundColor: "#F0F0F0"
                  }}>
                    <span>{tag}</span>
                    <FontAwesomeIcon icon={faRemove} className="ml-2 cursor-pointer" onClick={() => {
                      setTags(tags.filter((t) => t !== tag));
                    }}/>
                  </div>
                );
              })}
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <button
            className="mr-5"
            onClick={() => {
              if (isTranslated) {
                return;
              } else {
                handleTranslation();
              }
            }}
          >
            <FontAwesomeIcon icon={faLanguage} />
            <span className="ml-2">
              {isTranslating && "Translating..."}
              {!isTranslating && !isTranslated && "Translate"}
              {isTranslated ? "Translated" : ""}
            </span>
          </button>
          <button
            className="addBtn"
            onClick={sentence.length > 0 ? () => handleAddSentence() : () => {}}
            style={{
              borderRadius: "0.5rem",
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ml-2">Save Sentence</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
