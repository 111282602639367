import React, { useState } from "react";
import { useEffect } from "react";
import Alert from "react-bootstrap/Alert";

const commonLanguages = [
  "French",
  "Spanish",
  "German",
  "Italian",
  "Portuguese",
  // Add more common languages as needed
];

const SignUpForm = ({ register, errorFromParent }) => {
  const [learningLanguage, setLearningLanguage] = useState("");
  const [nativeLanguage, setNativeLanguage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");
  const [usersName, setUsersName] = useState("");

  const handleRegister = (event) => {
    event.preventDefault();
    if (learningLanguage === "" || nativeLanguage === "") {
      setError("You must select a language for both fields.");
      return;
    } else if (email === "" || password === "") {
      setError("You must enter an email and password.");
      return;
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    } else if (password.length < 6) {
      setError("Password must be at least 6 characters.");
      return;
    } else if (password.length > 20) {
      setError("Password must be less than 50 characters.");
      return;
    } else {
      setError("");
      register(email, password, nativeLanguage, learningLanguage, usersName);
      setLearningLanguage("");
      setNativeLanguage("");
      setEmail("");
      setPassword("");
    }
  };

  const handleLearningLanguageChange = (e) => {
    e.preventDefault();
    setLearningLanguage(e.target.value);
  };

  const handleNativeLanguageChange = (e) => {
    e.preventDefault();
    if (e.target.value === learningLanguage) {
      setError(
        "You cannot select the same language as your learning language."
      );
      return;
    } else {
      setError("");
      setNativeLanguage(e.target.value);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const renderStep1 = () => (
    <div
      style={{
        width: "300px",
      }}
    >
      <h2>Tell us what language you are learning</h2>
      <form
        onSubmit={(e) => {
          setIsProcessing(true);
          setTimeout(() => {
            setIsProcessing(false);
          }, 1000);
          e.preventDefault();
          setStep(2);
        }}
      >
        <label
          style={{
            width: "100%",
          }}
        >
          <select
            value={learningLanguage}
            onChange={handleLearningLanguageChange}
            required
            className="dropDown"
          >
            <option value="">Select a language</option>
            {commonLanguages.map((language) => (
              <option key={language} value={language}>
                {language}
              </option>
            ))}
          </select>
        </label>
        <br />
        <button
          type="submit"
          className="nextBtn"
          style={{
            width: "100%",
          }}
        >
          Next
        </button>
      </form>
    </div>
  );

  const renderStep2 = () => (
    <div
      style={{
        width: "300px",
      }}
    >
      <h2>What is your native language?</h2>
      <form
        onSubmit={(e) => {
          setIsProcessing(true);
          setTimeout(() => {
            setIsProcessing(false);
          }, 1000);
          e.preventDefault();
          setStep(3);
        }}
      >
        <label
          style={{
            width: "100%",
          }}
        >
          <select
            value={nativeLanguage}
            onChange={handleNativeLanguageChange}
            required
            className="dropDown"
          >
            <option value="">Select a language</option>
            <option value="English">English</option>
          </select>
        </label>
        <br />
        <button type="submit" className="nextBtn">
          Next
        </button>
        <button
          type="button"
          className="backBtn"
          onClick={() => {
            if (error !== "") {
              setError("");
            }
            setStep(1);
          }}
        >
          Back
        </button>
      </form>
    </div>
  );

  const renderStep3 = () => (
    <div
      style={{
        width: "300px",
      }}
    >
      <h2>Enter Information</h2>
      <form
        onSubmit={handleRegister}
        style={{
          width: "100%",
        }}
      >
        <label
          style={{
            width: "100%",
          }}
        >
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{
              width: "100%",
            }}
            className="inputField"
            placeholder="Email"
          />
        </label>
        <br />
        <label
          style={{
            width: "100%",
          }}
        >
          <input
            type="text"
            value={usersName}
            onChange={(e) => {
              setUsersName(e.target.value);
            }}
            required
            style={{
              width: "100%",
            }}
            className="inputField"
            placeholder="Your name"
          />
        </label>
        <br />
        <label
          style={{
            width: "100%",
          }}
        >
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            style={{
              width: "100%",
            }}
            className="inputField"
            placeholder="Password"
          />
        </label>
        <br />
        <label
          style={{
            width: "100%",
          }}
        >
          <input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            style={{
              width: "100%",
            }}
            className="inputField"
            placeholder="Confirm Password"
          />
        </label>
        <br />
        <button type="submit" className="nextBtn">
          Sign Up
        </button>
        <button
          type="button"
          className="backBtn"
          onClick={() => {
            if (error !== "") {
              setError("");
            }
            setStep(2);
          }}
        >
          Back
        </button>
      </form>
    </div>
  );

  let currentStep;
  switch (step) {
    case 1:
      currentStep = renderStep1();
      break;
    case 2:
      currentStep = renderStep2();
      break;
    case 3:
      currentStep = renderStep3();
      break;
    default:
      currentStep = null;
  }

  return (
    <div
      style={{
        textAlign: "center",
        maxWidth: "500px",
        margin: "auto",
        padding: "30px",
        boxShadow: "0 0 10px rgba(0,0,0,0.1)",
        borderRadius: "10px",
      }}
    >
      {!isProcessing && (
        <h1
          className="fs-1 fw-bold text-center mb-4"
          onClick={() => {
            handleRegister();
          }}
        >
          Sign Up
        </h1>
      )}
      {isProcessing ? (
        <div
          className="spinner-border text-primary"
          role="status"
          style={{
            width: "3rem",
            height: "3rem",
            margin: "auto",
            display: "block",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        currentStep
      )}
      {error && (
        <Alert
          variant="danger"
          style={{
            marginTop: "10px",
            width: "300px",
          }}
        >
          {error}
        </Alert>
      )}
      {errorFromParent && (
        <Alert variant="danger" style={{ width: "300px" }}>
          An error occurred while signing up. Please try again.
        </Alert>
      )}
    </div>
  );
};

export default SignUpForm;
