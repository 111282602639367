import Img from "../../assets/hero.svg";
import { Container, Row, Col } from "react-bootstrap";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const Hero = () => {
  return (
    <Container
      className="mt-5"
      style={{
        minHeight: "30vh",
      }}
    >
      <Row className="flex flex-wrap-reverse">
        <Col xs={12} md={12} lg={6} className="d-flex flex-col mt-5">
          <h1
            className="display-5 fw-bold"
            style={{
              color: "#30e981",
            }}
          >
            Easy vocabulary learning.
          </h1>
          <p className="lead fw-normal mt-4 fs-5">
            Learn new vocabulary in context using songs trending where people
            speak the language you are learning.
          </p>
          <Link
            className="mt-4 rounded landingButtonResized"
            style={{
              backgroundColor: "#30e981",
              width: "20%",
              color: "white",
              height: "50px !important",
              padding: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            to="/signup"
          >
            Get Started
            <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
          </Link>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={6}
          className="d-flex justify-content-center"
          style={{}}
        >
          <img src={Img} alt="landing" 
            style={{
              height: "300px",
              
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};
