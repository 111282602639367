export function Title({ title, flag }) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <h1 className="text-4xl font-semibold mt-5">{title}</h1>
      {flag && (
        <span
          className="text-2xl p-2 rounded-full bg-gray-200 w-16 h-16 flex justify-center items-center"
          style={{ fontSize: "2rem" }}
        >
          {flag}
        </span>
      )}
    </div>
  );
}
