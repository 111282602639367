import { Navbar, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";

export const Navigation = () => {
  return (
    <Navbar
      expand="lg"
      style={{
        borderBottom: "1px solid #e9ecef",
      }}
    >
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto align-items-center">
            <div className="d-flex align-items-center">
              <Link to="/" style={{ marginLeft: "10px", marginRight: "10px" }}>
                Home
              </Link>
              <Link
                to="/Login"
                style={{ marginLeft: "10px", marginRight: "10px" }}
                className="loginBtn"
              >
                Login
              </Link>
              <Link
                to="/Signup"
                style={{ marginLeft: "10px" }}
                className="signUpBtn"
              >
                Signup
              </Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
