import { useState, useEffect } from "react";
import userCalls from "../../api/userCalls";
import { SentenceCard } from "../../components/cards/SentenceCard";
import { TagFilter } from "../../components/TagFilter";
import { Title } from "../../components/text/Title";
import { PrivateWrapper } from "../../components/wrappers/PrivateWrapper";

export const MySentences = () => {
  const calls = new userCalls();

  const [sentences, setSentences] = useState([]);

  async function getSentences() {
    const sentences = await calls.getUserSentences();
    if (sentences.doc) {
      setSentences(sentences.doc);
    }
  }

  useEffect(() => {
    getSentences();
  }, []);

  const [search, setSearch] = useState("");

  const [aggregatedTags, setAggregatedTags] = useState([]);

  const [tagsToFilterBy, setTagsToFilterBy] = useState([]);

  useEffect(() => {
    const allTags = Array.from(
      new Set(sentences.flatMap((sentence) => sentence.tags))
    );
    setAggregatedTags(allTags);
  }, [sentences]);

  useEffect(() => {
    if (search !== "") {
      setSearch("");
    }
  }, [tagsToFilterBy]);

  useEffect(() => {
    if (tagsToFilterBy.length > 0) {
      setTagsToFilterBy([]);
    }
  }, [search]);

  return (
    <PrivateWrapper>
      <div
        style={{
          width: "80%",
          margin: "auto",
        }}
      >
        <Title title={`My sentences (${sentences.length})`} />
        <div className="mt-5 d-flex justify-content-between align-items-center flex-wrap">
          <div>
            <input
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              style={{
                backgroundColor: "#f2f2f2",
                border: "1px solid #eafdf2",
                width: "400px",
              }}
              className="p-2 rounded"
              placeholder="Search"
            />
          </div>
          <TagFilter
            tags={aggregatedTags}
            setTagsToFilterBy={(tag) => {
              if (tagsToFilterBy.includes(tag)) {
                setTagsToFilterBy(tagsToFilterBy.filter((t) => t !== tag));
              } else {
                setTagsToFilterBy(
                  [...tagsToFilterBy, tag].filter((tag, index, self) => {
                    return self.indexOf(tag) === index;
                  })
                );
              }
            }}
            tagsToFilterBy={tagsToFilterBy}
          />
        </div>
        {sentences &&
          search === "" &&
          tagsToFilterBy.length === 0 &&
          sentences?.map((sentence) => {
            return (
              <SentenceCard
                key={sentence._id}
                sentence={sentence.sentence}
                translation={sentence.translation}
                language={sentence.language}
                dateSaved={sentence.dateAdded}
                tags={sentence.tags}
                rerenderInParent={() => {
                  getSentences();
                }}
                id={sentence._id}
              />
            );
          })}
        {sentences &&
          search !== "" &&
          tagsToFilterBy.length === 0 &&
          sentences
            .filter((sentence) => {
              return (
                sentence.sentence
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                sentence.translation
                  .toLowerCase()
                  .includes(search.toLowerCase())
              );
            })
            .map((sentence) => {
              return (
                <SentenceCard
                  key={sentence._id}
                  sentence={sentence.sentence}
                  translation={sentence.translation}
                  language={sentence.language}
                  dateSaved={sentence.dateAdded}
                  tags={sentence.tags}
                  rerenderInParent={() => {
                    getSentences();
                  }}
                  id={sentence._id}
                />
              );
            })}
        {sentences.length === 0 && (
          <div className="mt-5 text-center">You have no sentences saved.</div>
        )}
        {sentences &&
          search !== "" &&
          sentences.filter((sentence) => {
            return (
              sentence.sentence.toLowerCase().includes(search.toLowerCase()) ||
              sentence.translation.toLowerCase().includes(search.toLowerCase())
            );
          }).length === 0 && (
            <div className="mt-5 text-center">No results found.</div>
          )}
        {tagsToFilterBy.length > 0 &&
          !search &&
          sentences
            .filter((sentence) => {
              return sentence.tags.some((tag) => tagsToFilterBy.includes(tag));
            })
            .map((sentence) => {
              return (
                <SentenceCard
                  key={sentence._id}
                  sentence={sentence.sentence}
                  translation={sentence.translation}
                  language={sentence.language}
                  dateSaved={sentence.dateAdded}
                  tags={sentence.tags}
                  rerenderInParent={() => {
                    getSentences();
                  }}
                  id={sentence._id}
                />
              );
            })}
      </div>
    </PrivateWrapper>
  );
};
