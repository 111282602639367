import {
  faUser,
  faSpellCheck,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "react-bootstrap";
import { useContext } from "react";
import { Auth } from "../../App"

export const InformationBanner = ({ text }) => {
  const { userProfile } = useContext(Auth);

  const information = [
    {
      icon: faUser,
      text: userProfile?.email || "User",
    },
    {
      icon: faSpellCheck,
      text: userProfile?.vocabulary?.length || 0,
    },
    {
      icon: faBook,
      text: userProfile?.sentences?.length || 0,
    },
  ];


  return (
    <div
      className="d-flex 
        justify-content-left align-items-center text-white bg-important p-1"
      style={{
        gap: ".5rem",
        height: "2.5rem",
      }}
    >
      <Container
        className="d-flex 
        justify-content-left align-items-center text-white bg-important"
      >
        {information.map((item, index) => {
          return (
            <div
              className="d-flex
            justify-content-center align-items-center text-white bg-important
            "
            >
              <FontAwesomeIcon icon={item.icon} className="ml-3" />
              <p className="ml-3">{item.text}</p>
            </div>
          );
        })}
      </Container>
    </div>
  );
};
