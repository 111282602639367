import React from "react";
import { Container } from "react-bootstrap";
import { Image, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Tutorial() {
  return (
    <div
      style={{
        backgroundColor: "#30e981",
        minHeight: "40vh"
      }}
      className="mt-5 d-flex align-items-center justify-content-center"
    >
      <Container className="d-flex align-items-center justify-content-center">
        <Col xs={12} md={4} lg={6}>
          <div
            style={{
              color: "white",
            }}
            className="mt-4 text-center"
          >
            <h3 className="fw-bold fs-3">
              LyricMster is a great way of learning new words.
            </h3>
            <p className="mt-3 fs-5">
              Just make an account, select the language you are learning, and
              start learning new words!
            </p>
            <br />
            <Link to="/signup" className="mt-5 rounded" style={{
              backgroundColor: "white",
              width: "200px !important",
              color: "#30e981",
              height: "50px !important",
              padding: "12px",
              fontWeight: "bold",
            }}>
              Get Started
            </Link>
          </div>
        </Col>
      </Container>
    </div>
  );
}

export default Tutorial;
