import { useState, useEffect } from "react";
import { VocabItem } from "./VocabItem";
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { VocabularyOptionsHeader } from "./VocabularyOptionsHeader";
import { Alert } from "react-bootstrap";
import { SearchBar } from "../SearchBar";

export const VocabularyOptions = ({
  savedWords,
  recentlySavedWords,
  deleteWord,
  error,
  art,
  isSavingWord,
  isDeletingWord,
}) => {
  const [activeView, setActiveView] = useState("savedWords");
  const [search, setSearch] = useState("");
  return (
    <div className="vocabularyOptions">
      <SearchBar setSearchText={setSearch} />
      <VocabularyOptionsHeader art={art} />
      {error !== null && (
        <Alert
          variant="danger"
          dismissible
          style={{
            maxWidth: "90%",
            margin: "auto",
          }}
          className="mt-4 mb-4"
        >
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>There is no need to add the same word twice. Please try again.</p>
        </Alert>
      )}
      <div className="d-flex justify-content-around rounded p-2">
        <button
          className={`p-3 rounded font-semibold  inactiveBtn ${
            activeView === "savedWords"
              ? "activeBtn"
              : "inactiveBtn fw-semibold"
          }`}
          onClick={() => setActiveView("savedWords")}
        >
          <FontAwesomeIcon icon={faArrowRightArrowLeft} className="mr-2" />
          Saved words
        </button>
        <button
          className={`p-3 rounded inactiveBtn ${
            activeView === "recentlySavedWords" ? "activeBtn" : ""
          }`}
          onClick={() => setActiveView("recentlySavedWords")}
        >
          <FontAwesomeIcon icon={faArrowRotateLeft} className="mr-2" />
          Recently saved words
        </button>
      </div>
      <div>
        <p className="mb-4 pl-4 flex flex-col">
          {activeView === "savedWords" &&
            !isSavingWord &&
            !isDeletingWord &&
            search === "" &&
            savedWords?.length !== 0 &&
            !isSavingWord &&
            savedWords?.map((word, index) => {
              return (
                <VocabItem
                  word={word.word}
                  deleteWord={(word) => deleteWord(word)}
                />
              );
            })}
          {activeView === "savedWords" &&
            !isSavingWord &&
            !isDeletingWord &&
            search !== "" &&
            savedWords
              ?.filter((word) =>
                word.word.toLowerCase().includes(search.toLowerCase())
              )
              .map((word, index) => {
                return (
                  <VocabItem
                    word={word.word}
                    deleteWord={(word) => deleteWord(word)}
                  />
                );
              })}
          {activeView === "savedWords" &&
            !isSavingWord &&
            !isDeletingWord &&
            search !== "" &&
            savedWords?.filter((word) =>
              word.word.toLowerCase().includes(search.toLowerCase())
            ).length === 0 &&
            "No results found"}
          {savedWords?.length === 0 &&
            activeView === "savedWords" &&
            "No saved words"}
          {activeView === "recentlySavedWords" &&
            recentlySavedWords?.length === 0 &&
            "No recently saved words"}
          {activeView === "recentlySavedWords" &&
            recentlySavedWords.map((word, index) => {
              return (
                <span
                  className="mr-3 pt-3 pb-3 flex justify-content-between"
                  style={{
                    borderBottom: "1px solid #F2F2F2",
                    borderTop: "1px solid #F2F2F2",
                  }}
                >
                  <span className="fw-bold">{word}</span>
                  <span
                    className="ml-3 fw-normal"
                    style={{
                      color: "#222",
                      fontSize: "12px",
                    }}
                  >
                    Definition
                  </span>
                </span>
              );
            })}
          {isDeletingWord && (
            <div>
              <div className="flex justify-content-center mt-4">
                <div className="spinner-border text-danger" role="status"></div>
              </div>
              <div>
                <p className="text-center mt-4">
                  Deleting word. Please wait...
                </p>
              </div>
            </div>
          )}
          {isSavingWord && (
            <div>
              <div className="flex justify-content-center mt-4">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
              <div>
                <p className="text-center mt-4">Saving word. Please wait...</p>
              </div>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};
