import { AnnouncementBanner } from "../Banners/AnnouncementBanner";
import { Navigation } from "../Navigation";

export const PublicWrapper = ({ children }) => {
  return (
    <>
      <Navigation />
      {/* <AnnouncementBanner 
        text="More languages coming soon!"  
      /> */}
      {children}
    </>
  );
};
