import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Login = ({
  login,
  setLoginEmail,
  setLoginPassword,
  emailInputRef,
}) => {
  return (
    <div
      className="d-flex flex-column"
      style={{
        width: "400px",
      }}
    >
      <input
        type="text"
        placeholder="Email address"
        onChange={(event) => setLoginEmail(event.target.value)}
        className="p-2 rounded"
        style={{
          border: "1px solid #f5f5f5",
        }}
        ref={emailInputRef}
      />
      <input
        type="password"
        placeholder="Password"
        onChange={(event) => setLoginPassword(event.target.value)}
        className="mt-3 p-2 rounded"
        style={{
          border: "1px solid #f5f5f5",
        }}
      />
      <span
        onClick={() => login()}
        className="p-3 text-center rounded fw-bold mt-3"
        style={{
          backgroundColor: "#13f375",
          color: "#fff",
        }}
      >
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        Sign In With Email
      </span>
      <span className="text-center mt-3">
        Don't have an account? <Link to="/Signup">Sign up here</Link>
      </span>
    </div>
  );
};
