import CTA from "../../components/Landing/CTA";
import Functionality from "../../components/Landing/Functionality";
import { Hero } from "../../components/Landing/Hero";
import Tutorial from "../../components/Landing/Tutorial";
import { PublicWrapper } from "../../components/wrappers/PublicWrapper";

export const Landing = () => {

  const functionality = [
    {
      title: "Learn new words",
      description:
        "LyricMster is a great way of learning new words. Just make an account, select the language you are learning, and start learning new words!",
    },
    {
      title: "Save your words",
      description:
        "LyricMster allows you to save your words and lyrics so you can come back to them later. You can also add your own words and lyrics!",
    },
    {
      title: "Make example sentences",
      description:
        "LyricMster allows you to make example sentences for your words so you can see how they are used in context.",
    },
  ];

  return (
    <PublicWrapper>
      <Hero />
      <Tutorial />
      <Functionality functionality={functionality} />
      <div className="mt-5">
        <CTA />
      </div>
    </PublicWrapper>
  );
};
