import { Col, Container, Row } from "react-bootstrap";

function Functionality({ functionality }) {
  return (
    <Container className="d-flex align-items-center justify-content-center" style={{
      minHeight: "55vh"
    }}>
      <Row>
        {functionality.map((item, index) => {
          return (
            <Col
              key={index}
              className="d-flex flex-column mt-5"
              xs={12}
              md={4}
              lg={4}
            >
              <h2
                className="fw-bold fs-3"
                style={{
                  color: "#30e981",
                }}
              >
                {item.title}
              </h2>
              <p
                style={{
                  fontSize: "1.1rem",
                  lineHeight: "1.5rem",
                  color: "#212529"
                }}
                className="mt-3"
              >
                {item.description}
              </p>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Functionality;
