export const UserInfoCard = ({ content, length, name }) => {
  return (
    <div
      className="flex flex-col rounded-lg shadow-md p-5 mt-4 cursor-pointer"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img
            src="https://img.icons8.com/ios/50/000000/checked-2--v1.png"
            alt="icon"
            className="h-5 w-5"
          />
          <h1 className="text-lg font-semibold">{content}</h1>
        </div>
        <h1 className="text-lg font-semibold">{length}</h1>
      </div>
    </div>
  );
};
